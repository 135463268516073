import 'bootstrap'
import '../scss/application.scss'

// Used for DELETE links and simple confirmation dialogs
import Rails from 'rails-ujs'
Rails.start();

require.context('../images', true)
require.context('../../../cms/media', true) // Load cms images

window.document.addEventListener('ncs-search', ({ detail: { resultType, action }}) => {
  window.gtag('event', 'page_view', { page_path: `/${resultType}/${action}` })
})
